.image_container{
  padding: 25px 20px 20px;
  width: 50%;
  /*background: #F3F3F3;*/
}
.search_result_head{
  margin:0;
}
.search_result_head p{
  font-size: 13px;
  margin: 0;
}
.image_container_inner{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}



.search_result{
  width: 33%;
  /*margin:0;*/
  padding: 7px 1.5%;
  background: #FFF;
  border-radius: 5px;
}

.search_result p{
  margin: 1px 0 0;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 11px;
  color: #4d4d4d;
  line-height: 1.2;
}

.search_result .image{
  background: #FAFAFA;
  display:block;
  border-radius: 10px;
  color: #000000;
  margin-bottom: 5px;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  width: 100%;
  height: 8vw;
  line-height: 8vw;
  overflow:hidden;
}

.search_result img{
  width: 100%;
  height: auto;
  border-radius: 10px;
  transform: translateY(calc(-50% + 4vw));
}