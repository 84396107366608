.my_body_message_box,
.body_message_box{
  position: relative;
  font-size: 13px;
  padding: 15px;
  background: #EDEBE1;
  border-radius: 10px;
}
.my_body_message_box{
  background-color: #EDEDED;
}

.body_message_box:after{
  position: absolute;
  content:"";
  display:inline-block;

  border: 10px solid black;
  border-width: 3px 10px;
  border-color: transparent transparent transparent #EDEBE1;
  border-color: transparent #EDEBE1 transparent transparent;
  left: -20px;
  top: 10px;
}
/*
.my_body_message_box:after{
  left: auto;
  right: -20px;
  border-color: transparent transparent transparent #EDEBE1;
}
*/
.body_message{
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-wrap;
}