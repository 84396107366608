
.my_meta,
.meta {
  width: 40px;
  line-height: 1.5;
  margin: 0 20px 0 0;
}
.my_meta{
  margin: 0 0 0px 20px;
}


.my_profile_icon,
.profile_icon{
  display: block;
  margin-top: 5px;
  width: 40px;
  height: 40px;
  background-color: #EFEFEF;
  border-radius: 100px;
}
.profile_icon{
  transform: rotateY(180deg);
}
.my_profile_icon img,
.profile_icon img{
  width: 100%;
  height: 100%;
}


.name,
.my_name{
  position:absolute;
  display: inline-block;
  top: 10px;
  font-size: 10px;
}
.my_name{
  right:45px;
  text-align:right;
}