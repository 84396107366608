.whiteboard_content{
  position: fixed;
  z-index: 1;
  right:0px;
  right:-10px;
  top: 65px;
  border: none;
  width: calc(50% - 2px);
  height: calc(100% - 140px);
  background: #FFFFFF;
}
.whiteboard_content canvas{
}