.container{
  padding: 0;
  /*padding-bottom: 100px;*/
}

.form{
  width: calc(100% - 201px);
  position: fixed;
  bottom: 0;
  left: 201px;
  padding: 20px;
  background: #FFFFFF;
  border-top: 1px solid #CCCCCC;
  z-index: 100;
}
.form input{
  width: 80%;
  padding: 5px 8px;
  font-size: 16px;
}
.form button{
  padding: 5px 8px;
  font-size: 16px;
}

.attachment{
  position:relative;
  width: 50px;
}
.attachment_close_button{
  z-index:1;
  position:absolute;
  right: -12px;
  top: -3px;
  background: #FFFFFF;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  border: 1px solid #CCCCCC;
  cursor: pointer;
}


.search_result{
  position:relative;
  margin:0 5px 0;
  top: 5px;
  padding: 3px 3px;
  border-radius: 5px;
  width: 50px;
  background: #FFFFFF;
}

.search_result .image{
  background: #DADADA;
  display:block;
  border-radius: 3px;
  color: #000000;
  margin: 0px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  height: 30px;
  line-height: 1;
  font-size:8px;
}

.search_result img{
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.search_result p{
  /*display:none;*/
  height: 12px;
  overflow: hidden;
  margin: 0 0 0;
  padding-left: 1px;
  padding-right: 1px;
  font-size: 10px;
  color: #4d4d4d;
}


