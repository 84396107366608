
.form{
  position:fixed;
  display:flex;
  align-items:baseline;
  justify-content:space-between;
  width: 100%;
  background: #FFFFFF;
  z-index: 100;
  bottom:0;

  border: 1px solid #EFEFEF;
  padding: 15px;
  margin: 5px 0 0;
  box-shadow: 1px 2px 3px rgba(0,0,0,.1);
}
.input_block{
  position: relative;
  width: calc(100% - 50px);
  /*border: 1px solid #CCCCCC;*/
  border-radius: 20px;
  background: #EDEDED;
  padding: 12px 15px 10px;
}
.input_block textarea{
  border:none;
  background:transparent;
  width: calc(100% - 40px);
  outline:none;
  padding: 0;
}
.input_block button{
  /*border:none;*/
  /*border:1px solid #FFFFFF;*/
  /*border:1px solid #CCCCCC;*/
  border-radius: 10px;
  background:transparent;
  width: 70px;
}
.icn_face{
  position:absolute;
  display: inline-block;
  right: 15px;
  bottom: 6px;
  cursor: pointer;
}
.icn_face img{
  width: 25px;
  height: 25px;
}

.icn_good{
  /*background-color: black;*/
  padding:10px;
  cursor: pointer;
}
.icn_good img{
  position:relative;
  top: 2px;
  width: 26px;
  height: 23px;
}

.form input{
  width: 90%;
  padding: 5px 8px;
  font-size: 16px;
  border:none;
}
.form button{
  display:none;
  padding: 5px 8px;
  font-size: 16px;
}

.attachment{
  position:relative;
  width: 50px;
}
.attachment_close_button{
  z-index:1;
  position:absolute;
  right: -8px;
  top: -8px;
  background: #FFFFFF;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  border: 1px solid #CCCCCC;
  cursor: pointer;
}

/*.search_result{*/
/*  position:relative;*/
/*  width: 50px;*/
/*  !*margin:0;*!*/
/*  padding: 0 0 10px;*/
/*  background: #FFF;*/
/*}*/

/*.search_result p{*/
/*  display:none;*/
/*  margin: 1px 0 0;*/
/*  padding-left: 5px;*/
/*  padding-right: 5px;*/
/*  font-size: 11px;*/
/*  color: #4d4d4d;*/
/*}*/

/*.search_result img{*/
/*  width: 100%;*/
/*  height: auto;*/
/*  border-radius: 7px;*/
/*}*/

