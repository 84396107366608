.modal_wrap{
    position:fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    left:0;
    top:0;
    z-index: 10000;
}
.overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    cursor: pointer;
}
.modalAttachment,
.modal {
    position: absolute;
    width: 600px;
    left: calc(50% - 300px);
    top: 20vh;
    height: 60vh;

    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.20);
    padding: 10px;
    z-index: 9990;
}
.modalAttachment .modal_inner,
.modal .modal_inner{
    height: calc( 60vh - 20px);
    overflow-y:scroll;
    padding: 10px 20px 20px;
}
.modalAttachment {
    position: absolute;
    width: 90vw;
    left: calc(50% - 45vw);
    top: 5vh;
    height: 90vh;
}
.modalAttachment .modal_inner{
    height: calc( 90vh - 20px);
    padding: 50px 20px 20px;
    text-align: center;
}
.modalAttachment img{
    width: 90%;
    height: auto;
}
.modalAttachment video{
    width: 100%;
    height: auto;
}

.close_button {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
/*//  background: #CCC;*/
    font-size: 1px;
    cursor: pointer;
}
.close_button:before,
.close_button:after{
     position:absolute;
     display:inline-block;
     content:"";
     width:30px;
     height: 1px;
     background: #999999;
     right:0;
     top:15px;
}
.close_button:before{
     transform:rotateZ(45deg);
 }
.close_button:after{
     transform:rotateZ(-45deg);
 }
.close_button:hover:before,
.close_button:hover:after{
     height: 1px;
     background: #333;
}

.modal_tab {
    position: absolute;
    top: -38px;
    left: 20px;
}
.modal_tab li {
    display: inline-block;
    border-radius: 15px 15px 0 0;
    padding: 10px 60px 8px;
    margin-right: 12px;
    font-size: 14px;
    box-shadow: 1px -3px 4px rgba(0, 0, 0, 0.10);
    cursor: pointer;
    background-color: #FFF;
    background-color: #707070;
    color: #FFFFFF;
}
.modal_tab li.current{
     background-color: #FFF;
     color: #333333;
}


.modal_content{
    padding: 20px;
}
.modal_content h2{
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
}

.buttons ul{
    list-style:none;
    display:flex;
    justify-content:space-around;
    margin: 0;
    padding: 30px 0 0;
}
.buttons li,
.buttons a{
    margin: 0;
    padding: 0;
    background-color: #000000;
    color: #FFFFFF;
    font-weight:bold;
    border-radius:10px;
}
.buttons a{
    text-decoration:none;
    padding: 13px 20px;
}
