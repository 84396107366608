
.entrance{
  text-align: center;
  width: 100%;
  background: #E9EADE;
}
.entrance_inner{
  width: 450px;
  margin:0 auto;
  margin-top: calc(50vh - 250px);
  text-align: center;
}
.logo_area{
  width:100%;
}
.logo{
  width: 150px;
  margin-bottom: 20px;
}
.logo_txt{
  width: 170px;
  margin-left: -20px;
}

.form{
  position:relative;
  margin-top: 30px;
  padding: 30px 0 0;
  width:100%;
}
.txt{
  font-size:13px;
  font-weight:bold;
}
.form input{
  padding: 10px;
  width:100%;
  text-align: center;
  border:1px solid #707070;
}
::placeholder{
  color: #AAAAAA;
}
.form a,
.form button{
  background-color: transparent;
  /*border-radius: 10px;*/
  border:none;
  padding: 5px 20px;
  margin-top:20px;
  color: #000000;
  cursor:pointer;
  font-weight:bold;
  text-decoration:none;
}
.form button:disabled{
  /*background-color: #FAFAFA;*/
  color: #CCCCCC;
  opacity:0.7;
}

.icons{
  display:flex;
  list-style:none;
  justify-content:space-between;
  margin:0 0 20px;
  padding: 0;
}
.icons li{
  cursor:pointer;
}
.icons i{
  border: 1px solid #CCCCCC;
  background: #FFFFFF;
  display:inline-block;
  width: 45px;
  height: 45px;
  border-radius: 100px;
}
.icons i.active_icon,
.icons i:hover{
  box-shadow:
          0 0 0px 2px #FFFFFF,
          0 0 0px 3px #999999;
}
.icons img{
  width: 100%;
  height: 100%;
}