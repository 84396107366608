.my_good_box,
.good_box{
}

.my_good_box{
  text-align: right;
}

.my_good_box img,
.good_box img{
  width: 35px;
  height:35px;
}
.good_box img{
  transform: rotateY(180deg);
}
