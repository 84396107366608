.mainarea,
.mainarea_active{
  position:relative;
  padding-top: 80px;
  width: 100%;
  min-height:calc(100vh - 80px);
}
.mainarea_active:after{
  position: absolute;
  display: inline-block;
  content:"";
  width: 1px;
  height: 100%;
  top: 0;
  left: calc( 50% - 1px);
  background: #000;
}
