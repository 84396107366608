
.message_blank,
.message{
  position: relative;
  padding: 0;
  display: flex;
  border-top: 1px solid #DADADA;
}
.message:before{
  position: absolute;
  display: inline-block;
  content: "";
  /*border: 3px solid #000000;*/
  border-radius:100px;
  background-color: #000000;
  width: 13px;
  height: 13px;
  top: -5px;
  left: calc(50% - 7px);
  z-index:1;
}
.message .chat_container{
  position: relative;
  padding: 20px 25px 25px;
  width: 50%;
}


.time_block {
  white-space: nowrap;
  position:absolute;
  max-width: 100px;
  top: -10px;
  padding:0 15px;
  background: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
  right: 5px;
  text-align: right;
}
/*
.message:nth-child(even) .time_block{
  left: calc(100% + 5px);
  right:auto;
  text-align: left;
}
 */
.my_chat_detail,
.chat_detail {
  display:flex;
}
.my_chat_detail{
  justify-content:flex-end;
  /*flex-direction: row-reverse;*/
}

.my_chat_detail .body,
.chat_detail .body {
  position:relative;
  width: calc( 100% - 80px);

  /*border: 1px solid #EFEFEF;*/
  margin: 5px 0 0;
  /*box-shadow: 1px 2px 3px rgba(0,0,0,.1);*/
}
