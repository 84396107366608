
.Keywords {
    position: relative;
}

.seeKeywords {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
    z-index:1;
}

.seeKeywords strong {
    color: #FFB2CD;
}
.icn_arrow_more{
    width: 15px;
    height: 15px;
    position: relative;
    margin-left:5px;
    top:3px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(5px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}


.seeKeywords:hover + .KeywordOverlay {
    display: block;
    animation-name: fadeIn;
    transform: translateY(0px);
    animation-duration: .2s;
}
.KeywordOverlay:hover{
    display: block;
    transform: translateY(0px);
    animation-name: fadeIn;
    animation-duration: .2s;
}
.KeywordOverlay {
    transform: translateY(5px);
    display: none;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 190px;
    padding: 35px 10px 10px;
    border-radius: 13px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    font-weight: normal;
    z-index: 2;
    text-align: left;
}

.KeywordOverlay ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.KeywordOverlay ul li {
    margin: 0;
    padding: 0;
    font-size: 12px;
}
.KeywordOverlay ul li span:before{
    position:absolute;
    content:"";
    width:3px;
    height:3px;
    border-radius: 100px;
    background: #000;
    left: 10px;
    top: calc(50% - 1px);
}
.KeywordOverlay ul li span {
    position:relative;
    display: block;
    text-decoration: none;
    color: #000000;
    padding: 2px 10px 2px 20px;
    border-radius: 3px;
    cursor: pointer;
}

.KeywordOverlay ul li span:hover {
    background-color: #EDEBE1;
}
