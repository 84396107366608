

.you_chat_detail .search_result{

}
.your_search_result,
.search_result{
  position: relative;
  width: 100%;
  padding: 15px;
  padding-left: 160px;
  min-height: 130px;
  margin-bottom: 0px;
  background: #EAE6DE;
  border-radius: 10px;
}
.your_search_result{
  background: #EDEDED;
}
.your_search_result:after,
.search_result:after{
  position: absolute;
  content:"";
  display:inline-block;

  border: 10px solid black;
  border-width: 8px 10px;
  border-color: transparent transparent transparent #E9EADE;
  border-color: transparent #E9EADE transparent transparent;
  left: -20px;
  top: 10px;
}
.your_search_result:after{
  display: none;
  /*
  left: auto;
  right: -20px;
  border-color: transparent transparent transparent #E9EADE;
   */
}
/*

.your_search_result p,
.search_result p{
  margin: 1px 0 0;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 11px;
  color: #4d4d4d;
  line-height: 1.2;
}
*/


.your_search_result .image,
.search_result .image{
  position: absolute;
  display:block;
  border-radius: 10px;
  /*background: #FAFAFA;*/
  color: #FFFFFF;
  margin-bottom: 10px;

  text-decoration: none;
  font-size: 12px;
  text-align: center;

  /*width: 60%;*/
  /*max-width: 300px;*/
  /*max-width: 140px;*/

  width: 140px;
  height: 100px;

  top: 20px;
  left: 10px;
  /*height: 13vw;*/
  /*line-height: 13vw;*/
  /*overflow:hidden;*/
}


.your_search_result img,
.search_result img{
  width: 100%;
  height: auto;
  border-radius: 10px;

  width: 140px;
  height: 100px;
  object-fit: cover;

  /*transform: translateY(calc(-50% + 6vw));*/

}

.title{
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 13px;
  line-height:1.3;
}
.description{
  font-size: 11px;
  white-space: pre-wrap;
  line-height:1.5;
  margin-top: 7px;

}
.button_wrap{
  display:flex;
  /*justify-content: flex-end;*/
  justify-content: center;
  margin:10px auto 0;
  text-align: center;
}
.button_wrap a{
  right:10px;
  top:10px;
  position: absolute;
  display: inline-block;
  text-align: center;
  background: #FFB2CD;
  color: #454545;
  font-size: 10px;
  font-weight:bold;
  border-radius: 100px;
  padding:2px 20px 1px;
  text-decoration:none;
}

.icons {
  position: absolute;
  right: 10px;
  bottom: -5px;
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 100px;
  background: #FFFFFF;
  display:inline-block;
}
.you_chat_detail .icons{
  right: auto;
  left: 10px;
}
.icons li {
  display: inline-block;
  padding: 0 5px;
  line-height:1.5;
  margin: 0;
  color:#FFB2CD;
  font-size: 10px;
  font-weight:bold;
  cursor: pointer;
}
.icons li:hover{
  opacity:0.8;
}
