.room_header{
  position:fixed;
  display:flex;
  justify-content: space-between;
  width: 100%;
  height:65px;
  right: 0;
  top:0;
  z-index: 10000;
  /*background-color: #EDEBE1;*/
  background-color: #FFFFFF;

  /*background: linear-gradient(#edebe1 0%, #f8f6ec 100%);*/
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.20);
}
.room_header_left{
  width: 50%;
  padding: 0px 20px 0;
  display: flex;
}
.logo{
  width: 28px;
  height: 28px;
  margin-top: 20px;
  margin-right: 30px;
}
.room_name_wrap{
  margin:10px 0 0;
  padding: 0;
}
.room_name{
  font-weight:bold;
  padding-top: 5px;
  padding-top: 10px;
  padding-bottom: 1px;
}
.room_name input{
  border:none;
  font-size:17px;
  font-size:20px;
  font-weight:bold;
  outline:none;
  background-color: transparent;
  /*background-color: #fff;*/
}
.room_topic{
  font-weight:bold;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  display:none;
}
.room_topic input{
  border:none;
  font-size:13px;
  outline:none;
  background-color: transparent;
  color: #333333;
  /*background-color: #fff;*/
  width: 100%;
}


ul.room_members{
  margin:20px 0 0;
  padding:0;
  list-style:none;
}
ul.room_members li{
  margin:0;
  padding:0;
  display:inline-block;
}



.menu_block{
  display:flex;
  justify-content:flex-end;
  align-items: flex-start;
  padding: 20px 20px 0;
  margin:0;
}

.icn_help,
.icn_arrow_bottom,
.icn_bell {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.icn_bell img{
  width:22px;
  height: 24px;
}
.icn_help img{
  width:24px;
  height: 24px;
}
.icn_arrow_bottom{
  width: 25px;
  text-align: center;
}
.icn_arrow_bottom img{
  width:13px;
  height: 7px;
}

.profile_icon{
  display: inline-block;
  font-style:normal;
  font-size: 16px;
  margin: 0 0 0 13px;
  background-color: #CCCCCC;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  line-height: 35px;
  text-align: center;
}
.profile_icon img{
  width: 100%;
  height: 100%;
}
.profile_name{
  margin:0;
  padding: 2px 0 0;
  line-height:2;
}


.inviteBox{
  position: relative;
  display:inline-block;
  border-radius: 100px;
  background-color: #fff;
  background-color: #f0f0f0;
  font-weight: normal;
  font-size: 13px;
  height: 26px;
  margin-left:0px;
  margin-right: 15px;
  cursor: pointer;
  padding: 0px 15px 0px 35px;
}
.inviteBox span{
  display: inline-block;
  white-space: nowrap;
  max-width: 170px;
  padding: 0 0;
  /*background: red;*/
  line-height:26px;
  /*overflow-x: scroll;*/
  overflow:hidden;

}
.inviteBox img{
  position: absolute;
  top: 7px;
  width: 12px;
  height: 12px;
}
.inviteBox img.icn_comment{
  left: 10px;
  width: 18px;
  height: 15px;
}
.inviteBox img.icn_copy{
  right: 11px;
}
.icn_invite{
  cursor: pointer;
}

/*議事録*/
.export{
  position:relative;
  margin:0 10px;
  border: 2px solid #000000;
  border-radius: 7px;
  padding: 1px 5px;
  font-size: 12px;
  cursor:pointer;
}
.export:hover{
  background-color: #000000;
  color: #FFFFFF;
  font-weight:bold;
}

/*言語*/
.lang_nav{
  display:flex;
  list-style:none;
  margin: 0;
  padding: 0;
  border:2px solid #000000;
  border-radius: 5px;
}
.lang_nav li{
  font-size:12px;
  margin: 0;
  padding: 0 4px;
  line-height:1.5;
  cursor: pointer;
  font-weight: bold;
}

.lang_nav li.lang_active{
  background: #000;
  color: #FFFFFF;
}


.icn_arrow_bottom:hover .functionMenu{
  display: block;
}
.functionMenu{
  display: none;
  position:absolute;
  right:-10px;
  top: 25px;
  width: 230px;
  padding: 10px;
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-weight:normal;
  z-index:1;
  text-align: left;
}
.functionMenu ul{
  margin: 0;
  padding: 0;
  list-style:none;
}
.functionMenu ul li{
  margin: 0;
  padding: 0;
  font-size:12px;
}
.functionMenu ul li:last-of-type{
  margin-top: 7px;
}
.functionMenu ul li a{
  display:block;
  text-decoration: none;
  color: #000000;
  padding: 4px 10px;
  border-radius: 3px;
}
.functionMenu ul li a:hover{
  background-color: #EDEBE1;
}