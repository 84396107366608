.toggle_lists{
  position: fixed;
  display: flex;
  justify-content: space-between;
  right: 10px;
  top: 80px;
  background-color: #EDEDED;
  border-radius: 100px;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index:1000;
}
.toggle_lists li{
  margin: 0;
  padding: 3px 10px;
  min-width: 100px;
  text-align: center;
  font-size: 12px;
}
.toggle_lists li:first-of-type{
  border-radius: 100px 0 0 100px;
}
.toggle_lists li:last-of-type{
  border-radius: 0 100px 100px 0;
}


.toggle_lists li.active{
  background-color: #F4B5CC;
}