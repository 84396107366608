
.icons,
.my_icons{
  list-style:none;
  position: absolute;
  right: 7px;
  bottom: -13px;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  display:inline-block;
}
.my_icons{
  right: auto;
  left: 7px;
}

.icons li{
  display:flex;
  flex-direction: row-reverse;
}
.my_icons li{
  display:flex;
}
.icons span,
.my_icons span{
  font-size: 11px;
  line-height: 18px;
  color: #666666
}
.icon{
  border-radius: 100px;
  background: #FFFFFF;
  display: inline-block;
  padding: 0;
  margin: 0 3px;
  width: 22px;
  height: 22px;
  line-height:20px;
  text-align:center;
  color:#F83B55;
  font-size: 10px;
  font-weight:bold;
  cursor: pointer;
}
.icon:hover{
  opacity:0.8;
}
.icon img{
  width: 12px;
}
